/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: archive-regular;
  src: url(assets/Archive-Regular.otf) format("opentype");
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 200,
    "opsz" 20;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
b,
button {
  text-transform: uppercase;
  font-family: archive-regular;
  // color: white;
}

* {
  font-family: "Roboto", sans-serif;
}

body {
  // background: radial-gradient(#0A2E4F, #13114A) !important;
  background-image: url("assets/gradient_background.svg");
}

.card {
  @apply bg-white bg-opacity-5 rounded;
}

.active {
  @apply bg-secondary;
}

.active-button {
  @apply btn-secondary;
}

/*  
  scrollbar styling
*/
*,
*:hover {
  scrollbar-color: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #0c1137;
  @apply bg-opacity-50;
  border-radius: 8px;
}



